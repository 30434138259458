
@import '@style/sfc.scss';

.modal-onboarding-meeting {
    &__description {
        margin: 0 o rem(24);
    }

    &__calendar {
        height: rem(670);
    }
}
